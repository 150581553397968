.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.tabs .tab a {
    color: #2f6ac6;
}

/*Black color to the text*/

.tabs .tab a:hover {
    background-color: #eee;
    color: #2f6ac6;
}

/*Text color on hover*/

.tabs .tab a.active,
.tabs .tab a:focus,
.tabs .tab a:focus.active {
    background-color: #eee;
    color: #2f6ac6;
}

/*Background and text color when a tab is active*/

.tabs .indicator {
    background-color: #2f6ac6;
}

.tabs .tab.disabled a, .tabs .tab.disabled a:hover {
    color: rgba(110, 143, 238, 0.4);
    cursor: default;
}

@media print
{
    .no-print, .no-print *
    {
        display: none !important;
    }
}
