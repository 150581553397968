.sidenav-overlay {
    z-index: 1;
}

#menu-header {
    text-align: left;
}

#header-logo {
    margin-left: 10px;
}
