.table-document {
    width: 30%
}

.table-description {
    width: 50%
}

.table-file {
    width: 10%
}

.upload-button {
    margin-left: 10px;
    line-height: 24px;
}

.material-icons{
    display: inline-flex;
    vertical-align: top;
}