.table-title {
    width: 40%;
}

.table-yes {
    width: 10%;
}

.table-comment {
    width: 50%;
}