.general-feeling-text {
    text-align: justify;
}

.logo-pdf {
    margin-left: 50px;
    margin-bottom: -90px;
}

.title-pdf {
    margin-bottom: -20px;
}

.table-auditReport-td1 {
    width: 50%;
}

table-auditReport-td2 {
    width: 50%;
}

table-auditReport-td1-bis {
    width: 40%;
}

table-auditReport-td2-bis {
    width: 40%;
}

table-auditReport-td3 {
    width: 10%;
}

table-auditReport-td4 {
    width: 10%;
}
